
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { priceRulesEl } from "@/utils/verify";
import FileUpload from "@/components/scope/file-upload.vue";
import GeneralVehicleSelect from "@/components/scope/general-vehicle-select.vue";
import { VueCropper } from "vue-cropper";
import { getUuid } from "@/utils/common";
import t from "@common/src/i18n/t";
const banner = namespace("banner");
const base = namespace("base");
@Component({
  components: { FileUpload, GeneralVehicleSelect, VueCropper }
})
export default class partsEdit extends Vue {
  @banner.Action addbanner;
  @base.Action getDictionaryList;
  @banner.Action getbannerList;
  @banner.Action editbanner;
  @base.Action getUploadToken;

  get breadData() {
    return [
      { name: t("v210831.banner-management"), path: "/management/banner-list" },
      {
        name: this.$route.name === "ManagementBannerAdd" ? t("v210831.new-banner") : t("v210831.edit-banner")
      }
    ];
  }
  bannerList = [];
  option = {
    img: "", // 裁剪图片的地址
    info: true, // 裁剪框的大小信息
    outputSize: 1, // 裁剪生成图片的质量
    outputType: "jpg", // 裁剪生成图片的格式
    canScale: true, // 图片是否允许滚轮缩放
    autoCrop: true, // 是否默认生成截图框
    autoCropWidth: 1050, // 默认生成截图框宽度
    autoCropHeight: 252, // 默认生成截图框高度
    fixedBox: true, // 固定截图框大小 不允许改变
    // canMove: true, // 截图框限制移动
    // fixed: true, // 是否开启截图框宽高固定比例
    // fixedNumber: [1, 1], // 截图框的宽高比例
    full: true, // 是否输出原图比例的截图
    canMoveBox: false, // 截图框能否拖动
    original: true, // 上传图片按照原始比例渲染
    centerBox: true, // 截图框是否被限制在图片里面
    // infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
    enlarge: 1 // 图片根据截图框输出比例倍数
  };
  dialogFormVisible = false;
  bannerFormData = {
    bannerName: "",
    bannerRange: "",
    params: "",
    bannerUrl: "",
    status: 1,
    showInterfaceTitle: false
  };
  defaultBannerList: any = [];
  storeTypeList: any = [];
  bannerFormRules = {
    bannerName: [{ required: true, message: t("v210831.please-enter-a-Banner-name"), trigger: "blur" }],
    // params: [{ required: true, message: "请输入Banner跳转参数", trigger: "blur" }],
    bannerRange: [{ required: true, message: t("v210831.please-enter-the-Banner-scope"), trigger: "blur" }],
    bannerUrl: [{ required: true, message: t("v210831.please-upload-banner-img"), trigger: "blur" }]
  };
  // 上传图片
  handleBannerUploadSuccess() {
    console.log("[ url ]");
    // if (this.$refs.bannerForm) {
    (this.$refs.bannerForm as any).clearValidate("bannerUrl");
    // }
    //上传成功后将图片地址赋值给裁剪框显示图片
    this.$nextTick(() => {
      // this.option.img = this.bannerFormData.bannerUrl;
    });
  }
  get isAdd() {
    return this.$route.name === "ManagementBannerAdd";
  }
  created() {
    this.init();
  }
  checkPrice(rule, value, callback) {
    if (!value) {
      callback();
    } else {
      priceRulesEl(value, callback);
    }
  }
  handleInsert() {
    (this.$refs.cropper as any).getCropBlob(blob => {
      this.uploadFileCb(this.blobChangeFile(blob));
    });
  }
  handleTailor() {
    this.dialogFormVisible = true;
  }
  submitForm(formName, status) {
    (this.$refs[formName] as any).validate(valid => {
      if (valid) {
        if (this.isAdd) {
          if (status === 1) {
            this.bannerFormData.status = status;
            this.handleaddBanner();
          } else {
            this.bannerFormData.status = status;
            this.handleaddBanner();
          }
        } else {
          if (status === 1) {
            this.bannerFormData.status = status;
            this.handleeditBanner();
          } else {
            this.bannerFormData.status = status;
            this.handleeditBanner();
          }
        }
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  async init() {
    this.getDictionaryList("banner_range").then(data => {
      console.log(data, "storeTypeList");
      this.storeTypeList = data;
    });
    this.getDictionaryList("banner_jump_page").then(data => {
      console.log(data, "banner_jump_page  ");
      this.bannerList = data;
    });
    if (!this.isAdd || this.$route.query.code) {
      const bannerData = await this.getbannerList({
        bannerCode: this.$route.params.id || this.$route.query.code
      });
      this.bannerFormData = bannerData.data.list[0];
      console.log(bannerData.data.list, "this.bannerFormData");

      this.defaultBannerList = bannerData.data.list.map(item => {
        var obj = { url: "" };
        obj.url = item.bannerUrl;
        return obj;
      });
    }
  }
  open() {
    this.$confirm(t("v210831.please-confirm-the"), t("v210831.tips"), {
      confirmButtonText: t("v210831.confirm-and-release"),
      cancelButtonText: t("base.cancel"),
      type: "warning"
    }).then(() => {
      this.submitForm("bannerForm", 1);
    });
  }
  async handleaddBanner() {
    await this.addbanner(this.bannerFormData).then(() => {
      this.bannerFormData.status === 1
        ? this.$message.success(t("v210831.publish-banner-successfully!"))
        : this.$message.success(t("v210831.save-banner-successfully!"));
      this.$router.push("/management/banner-list");
    });
  }
  async handleeditBanner() {
    await this.editbanner(this.bannerFormData).then(() => {
      this.bannerFormData.status === 1
        ? this.$message.success(t("v210831.publish-banner-successfully!"))
        : this.$message.success(t("v210831.save-banner-successfully!"));
      this.$router.push("/management/banner-list");
    });
  }
  // Blob转File
  blobChangeFile(blob) {
    let file = new File([blob], t("v210831.picture-clipping-jpg"), { type: "image/webp" });
    console.log("[ file ]", file);
    return file;
  }
  uploadTokenObj: any = {};
  uploadUrl: string = "";
  uploadFileCb(file) {
    return this.getUploadToken({
      businessType: "0",
      businessCode: "000000000"
    }).then(data => {
      this.uploadTokenObj = {
        url: data.data.host,
        key: `${data.data.dir}/${getUuid(15)}/${file.name}`,
        policy: data.data.policy,
        OSSAccessKeyId: data.data.accessId,
        Signature: data.data.signature,
        success_action_status: 200,
        domain: data.data.domain
      };
      this.uploadUrl = data.data.host;
      // this.changeValue(file);
      console.log("[ ------ ]");
      // console.log("[ this.$refs.upload ]", this.$refs.upload);
      // (this.$refs.upload as any).submit();
      this.uploadImage(file);
    });
  }
  uploadImage(file) {
    var xhr, formData;
    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open("POST", this.uploadUrl);
    formData = new FormData();
    Object.keys(this.uploadTokenObj).forEach(key => {
      formData.append(key, this.uploadTokenObj[key]);
    });
    formData.append("file", file);
    xhr.send(formData);
    xhr.onload = () => {
      if (xhr.status === 200) {
        this.bannerFormData.bannerUrl = this.uploadTokenObj.domain + "/" + this.uploadTokenObj.key;
        this.defaultBannerList = [];
        this.$nextTick(() => {
          this.defaultBannerList = [
            {
              name: file.name,
              url: this.uploadTokenObj.domain + "/" + this.uploadTokenObj.key
            }
          ];
        });
        this.dialogFormVisible = false;
      } else {
        // this.options.failure("HTTP Error: " + xhr.status);
        console.log('[ "HTTP Error: " + xhr.status ]', "HTTP Error: " + xhr.status);
      }
    };
  }

  submitUpload() {
    (this.$refs.upload as any).submit();
  }
  // ////
}
